import React, { useState } from 'react';
import { Sparkles, Download, Moon, Sun, Globe, X } from 'lucide-react';

const translations = {
  en: {
    title: "Wallpaper Generator",
    inputPlaceholder: "Describe your dream wallpaper...",
    generateButton: "Generate",
    generatedWallpapers: "Generated Wallpapers",
    download: "Download",
    selectPayment: "Select Payment Method",
    wechat: "WeChat Pay",
    stripe: "Stripe",
    cancel: "Cancel",
    processing: "Processing payment...",
    success: "Payment successful! Downloading...",
    error: "Payment failed. Please try again."
  },
  zh: {
    title: "壁纸生成器",
    inputPlaceholder: "描述您理想的壁纸...",
    generateButton: "生成",
    generatedWallpapers: "生成的壁纸",
    download: "下载",
    selectPayment: "选择支付方式",
    wechat: "微信支付",
    stripe: "Stripe",
    cancel: "取消",
    processing: "正在处理支付...",
    success: "支付成功！正在下载...",
    error: "支付失败。请重试。"
  }
};

const PaymentModal = ({ isOpen, onClose, onSelectPayment, t, isDarkMode }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className={`${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} p-6 rounded-lg shadow-xl`}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">{t.selectPayment}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="space-y-4">
          <button
            onClick={() => onSelectPayment('wechat')}
            className={`w-full py-2 px-4 rounded ${isDarkMode ? 'bg-green-600 hover:bg-green-700' : 'bg-green-500 hover:bg-green-600'} text-white transition duration-300`}
          >
            {t.wechat}
          </button>
          <button
            onClick={() => onSelectPayment('stripe')}
            className={`w-full py-2 px-4 rounded ${isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white transition duration-300`}
          >
            {t.stripe}
          </button>
          <button
            onClick={onClose}
            className={`w-full py-2 px-4 rounded ${isDarkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-200 hover:bg-gray-300'} transition duration-300`}
          >
            {t.cancel}
          </button>
        </div>
      </div>
    </div>
  );
};

const WallpaperGenerator = () => {
  const [prompt, setPrompt] = useState('');
  const [generatedWallpapers, setGeneratedWallpapers] = useState([]);
  const [language, setLanguage] = useState('en');
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [selectedWallpaper, setSelectedWallpaper] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);

  const t = translations[language];

  const generateWallpapers = () => {
    const newWallpapers = Array(6).fill(null).map((_, index) => ({
      id: index + 1,
      url: `https://www.claudeusercontent.com/api/placeholder/360/640?text=Wallpaper ${index + 1}`
    }));
    setGeneratedWallpapers(newWallpapers);
  };

  const openPaymentModal = (wallpaper) => {
    setSelectedWallpaper(wallpaper);
    setIsPaymentModalOpen(true);
  };

  const closePaymentModal = () => {
    setIsPaymentModalOpen(false);
    setPaymentStatus(null);
  };

  const processPayment = (method) => {
    setPaymentStatus('processing');
    // Simulate payment processing
    setTimeout(() => {
      const isSuccessful = Math.random() > 0.3; // 70% success rate
      if (isSuccessful) {
        setPaymentStatus('success');
        setTimeout(() => {
          window.open(selectedWallpaper.url, '_blank');
          closePaymentModal();
        }, 1500);
      } else {
        setPaymentStatus('error');
      }
    }, 2000);
  };

  const toggleLanguage = () => {
    setLanguage(prev => prev === 'en' ? 'zh' : 'en');
  };

  const toggleTheme = () => {
    setIsDarkMode(prev => !prev);
  };

  const sampleWallpapers = Array(5).fill(null).map((_, index) => ({
    id: index + 1,
    url: `https://www.claudeusercontent.com/api/placeholder/360/640?text=Sample ${index + 1}`
  }));

  const WallpaperGrid = ({ wallpapers }) => (
    <div className="grid grid-cols-3 gap-6">
      {wallpapers.map((wallpaper) => (
        <div key={wallpaper.id} className="relative group">
          <img
            src={wallpaper.url}
            alt={`Wallpaper ${wallpaper.id}`}
            className="w-full h-auto aspect-[9/16] object-cover rounded-lg shadow-md transition-transform duration-300 group-hover:scale-105"
          />
          <div className={`absolute inset-0 ${isDarkMode ? 'bg-gray-800' : 'bg-purple-400'} bg-opacity-0 group-hover:bg-opacity-70 transition-all duration-300 flex items-center justify-center rounded-lg`}>
            <button
              onClick={() => openPaymentModal(wallpaper)}
              className={`${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-purple-600'} px-4 py-2 rounded-full flex items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 shadow-lg`}
            >
              <Download className="w-4 h-4 mr-2" />
              {t.download}
            </button>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gradient-to-br from-purple-100 via-pink-100 to-blue-100'} p-8 relative transition-colors duration-300`}>
      <div className="absolute top-4 right-4 flex space-x-4">
        <button onClick={toggleLanguage} className={`p-2 rounded-full ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-purple-600'} shadow-md`}>
          <Globe className="w-5 h-5" />
        </button>
        <button onClick={toggleTheme} className={`p-2 rounded-full ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-purple-600'} shadow-md`}>
          {isDarkMode ? <Sun className="w-5 h-5" /> : <Moon className="w-5 h-5" />}
        </button>
      </div>

      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className={`w-full max-w-3xl ${isDarkMode ? 'bg-gray-800' : 'bg-white/80'} backdrop-blur-sm rounded-3xl shadow-xl overflow-hidden border ${isDarkMode ? 'border-gray-700' : 'border-purple-200'} p-8 transition-colors duration-300`}>
          <h1 className={`text-3xl font-bold ${isDarkMode ? 'text-white' : 'text-purple-600'} text-center mb-8`}>{t.title}</h1>
          
          <div className="mb-8">
            <div className={`flex rounded-full ${isDarkMode ? 'bg-gray-700' : 'bg-white'} shadow-md overflow-hidden`}>
              <input
                type="text"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder={t.inputPlaceholder}
                className={`flex-grow px-6 py-4 text-lg ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'} focus:outline-none`}
              />
              <button
                onClick={generateWallpapers}
                className={`${isDarkMode ? 'bg-purple-700 hover:bg-purple-800' : 'bg-purple-500 hover:bg-purple-600'} text-white px-8 py-4 flex items-center transition duration-300`}
              >
                <Sparkles className="w-5 h-5 mr-2" />
                {t.generateButton}
              </button>
            </div>
          </div>
          
          {generatedWallpapers.length > 0 ? (
            <div>
              <h2 className={`text-2xl font-semibold ${isDarkMode ? 'text-white' : 'text-purple-600'} mb-4`}>{t.generatedWallpapers}</h2>
              <WallpaperGrid wallpapers={generatedWallpapers} />
            </div>
          ) : (
            <div className="flex justify-between items-center">
              {sampleWallpapers.map((wallpaper) => (
                <div key={wallpaper.id} className="relative group w-1/5 px-1">
                  <img
                    src={wallpaper.url}
                    alt={`Sample ${wallpaper.id}`}
                    className="w-full h-auto aspect-[9/16] object-cover rounded-lg shadow-sm transition-transform duration-300 group-hover:scale-105"
                  />
                  <div className={`absolute inset-0 ${isDarkMode ? 'bg-gray-800' : 'bg-purple-400'} bg-opacity-0 group-hover:bg-opacity-70 transition-all duration-300 flex items-center justify-center rounded-lg`}>
                    <Download className={`w-5 h-5 ${isDarkMode ? 'text-gray-300' : 'text-white'} opacity-0 group-hover:opacity-100 transition-opacity duration-300`} />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <PaymentModal
        isOpen={isPaymentModalOpen}
        onClose={closePaymentModal}
        onSelectPayment={processPayment}
        t={t}
        isDarkMode={isDarkMode}
      />

      {paymentStatus && (
        <div className={`fixed bottom-4 right-4 p-4 rounded-lg shadow-lg ${
          paymentStatus === 'processing' ? 'bg-yellow-500' :
          paymentStatus === 'success' ? 'bg-green-500' :
          'bg-red-500'
        } text-white`}>
          {paymentStatus === 'processing' && t.processing}
          {paymentStatus === 'success' && t.success}
          {paymentStatus === 'error' && t.error}
        </div>
      )}
    </div>
  );
};

export default WallpaperGenerator;